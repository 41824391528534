/* Coolors Exported Palette - coolors.co/e8aeb7-b8e1ff-a9fff7-94fbab-82aba1 */

/* HSL */
$color1: hsla(351, 56%, 80%, 1);
$softblue: hsla(205, 100%, 86%, 1);
$color3: hsla(174, 100%, 83%, 1);
$color4: hsla(133, 93%, 78%, 1);
$color5: hsla(165, 20%, 59%, 1);

/* RGB */
$color1: rgba(232, 174, 183, 1);
$softblue: rgba(184, 225, 255, 1);
$color3: rgba(169, 255, 247, 1);
$color4: rgba(148, 251, 171, 1);
$color5: rgba(130, 171, 161, 1);